import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from 'components/Layout'
import SiteMetadata from 'queries/SiteMetadata'

export default ({ data }) => {
  const {
    trottingProducts: trottingLink,
    advertising: advertisingLink,
  } = SiteMetadata().links

  return (
    <Layout pageTitle='Home'>
      <oma-grid-row>
        <section>
          <h1 className='heading page__heading'>
            Din leverantör av reklam- och travprodukter
          </h1>
          <p>
            Team Bäck AB är ett litet företag med stort utbud. Vi har lång
            erfarenhet inom säljbranchen och de rätta kontakterna för att kunna
            leverera kvalitétsprodukter till bra priser. Vi gillar att jobba
            nära våra kunder och skapa långsiktiga relationer där kunden står i
            fokus. Vi jobbar med trygghet och kvalitet och är med dig hela vägen
            från beställning tills dess att du har varan hemma hos dig.
          </p>
        </section>
        <section>
          <div className='business-areas'>
            <div className='business-area'>
              <Link to={advertisingLink}>
                <h2 className='business-area__heading'>Reklamprodukter</h2>
                <Img
                  className='business-area__image'
                  fluid={data.advertising.childImageSharp.fluid}
                />
              </Link>
              <p>
                Vi erbjuder brett sortiment av reklamprodukter. Några av våra
                mest köpta varor är rolluper, beachflaggor, banderoller och
                vanliga flaggor. Vi gör också mycket give away-produkter. Vi har
                exempelvis levererat till Bosch, Stora Enso, Gävleortens
                Mejeriförening, Avis biluthyrning och Mellansvenska Skog.
              </p>
              <p>
                <Link to={advertisingLink}>Läs mer om vårt utbud här.</Link>
              </p>
            </div>
            <div className='business-area'>
              <Link to={trottingLink}>
                <h2 className='business-area__heading'>
                  Travsport och ridsport
                </h2>
                <Img
                  className='business-area__image'
                  fluid={data.trottingProducts.childImageSharp.fluid}
                />
              </Link>
              <p>
                Vi kan erbjuda det du behöver till dina trav- och ridhästar. Vi
                har ett brett utbud och kan leverera de flesta sorters tränings-
                och tävlingsvagnar. Om du har en gammal sulki som du vill
                renovera så kan vi också hjälpa dig.
              </p>
              <p>
                <Link to={trottingLink}>Läs mer om vårt utbud här.</Link>
              </p>
            </div>
          </div>
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  query {
    trottingProducts: file(relativePath: { eq: "trotting-products.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    advertising: file(relativePath: { eq: "advertising/rollup-bosch.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
